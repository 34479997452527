<template>
  <section class="side-navigation-menu" :class="{ 'is-open': isCollapse }" @mouseenter="expandSidebar" @mouseleave="collapseSidebar">
    <el-scrollbar>
      <el-menu
        :default-active="activeLink"
        :collapse="!isCollapse"
        active-text-color="#F754A2"
        ref="sidebarMenu"
        :class="{ 'default-style': !brandingInfo.styled_sidemenu, 'inverted-color-style': brandingInfo.styled_sidemenu }"
      >
        <template v-for="(sideBarItem, i) in menu">
          <template v-if="sideBarItem.children && sideBarItem.children.length">
            <el-submenu :key="sideBarItem._id" :index="sideBarItem._id">
              <template slot="title">
                <img
                v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                  :src="require('@/assets/img/icons/menu/' + sideBarItem.icon)"
                  alt="menu"
                  style="height: 22px; width: 22px"
                />&nbsp;&nbsp;
                <!-- <span slot="title">{{ sideBarItem.title }}</span> -->
                <span :title="sideBarItem.title.length > 15 ? sideBarItem.title : ''" class="submenu">{{ sideBarItem.title | truncate(15, "...") }}</span>
              </template>

              <el-menu-item-group>
                <template v-for="sideBarSubItem in sideBarItem.children">
                  <template v-if="sideBarSubItem.type == 'Child'">
                    <el-menu-item
                      :key="sideBarSubItem._id"
                      :index="sideBarSubItem._id"
                      @click="routeClick(sideBarSubItem, true)"
                      v-if="checkPermissionsStatus(sideBarSubItem)"
                    >
                      <img
                      v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                        :src="
                          require('@/assets/img/icons/menu/' +
                            sideBarSubItem.icon)
                        "
                        alt="menu"
                        style="height: 22px; width: 22px"
                      />
                      &nbsp;&nbsp;
                      <!-- <span class="menu-title">{{ sideBarSubItem.title }}</span> -->
                      <span class="menu-title" :title="sideBarSubItem.title.length > 11 ? sideBarSubItem.title : ''">
                            {{
                            sideBarSubItem.title | truncate(11, "...")
                            }}
                          </span>
                    </el-menu-item>
                  </template>
                  <template v-if="sideBarSubItem.type == 'SubParent'">
                    <el-submenu
                      :key="sideBarSubItem._id"
                      :index="sideBarSubItem._id"
                    >
                      <template slot="title">
                        <img
                        v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                          :src="
                            require('@/assets/img/icons/menu/' +
                              sideBarSubItem.icon)
                          "
                          alt="menu"
                          style="height: 22px; width: 22px"
                        />&nbsp;&nbsp;
                        <!-- <span class="menu-title" slot="title">{{
                          sideBarSubItem.title
                        }}</span> -->
                          <span class="menu-title" :title="sideBarSubItem.title.length > 10 ? sideBarSubItem.title : ''">{{ sideBarSubItem.title | truncate(10, "...") }}</span>
                      </template>
                      <el-menu-item-group>
                        <template
                          v-for="sideBarSubSubItem in sideBarSubItem.children"
                        >
                          <el-menu-item
                            :key="sideBarSubSubItem._id"
                            :index="sideBarSubSubItem._id"
                            @click="routeClick(sideBarSubSubItem, true)"
                            v-if="checkPermissionsStatus(sideBarSubSubItem)"
                          >
                            <img
                            v-if=" getCompanyDetails && getCompanyDetails.menuIcon"
                              :src="
                                require('@/assets/img/icons/menu/' +
                                  sideBarSubSubItem.icon)
                              "
                              alt="menu"
                              style="height: 22px; width: 22px"
                            />
                            &nbsp;&nbsp;
                            <!-- <span class="menu-title">{{
                              sideBarSubSubItem.title
                            }}</span> -->
                             <span  class="menu-title" :title="sideBarSubSubItem.title.length > 8 ? sideBarSubSubItem.title : ''">
                            {{
                            sideBarSubSubItem.title | truncate(8, "...")
                            }}
                          </span>
                          </el-menu-item>
                        </template>
                      </el-menu-item-group>
                    </el-submenu>
                  </template>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item
              :key="i + sideBarItem._id"
              :index="sideBarItem._id"
              @click="routeClick(sideBarItem, false)"
              v-if="checkPermissionsStatus(sideBarItem)"
            >
              <img
              v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                :src="require('@/assets/img/icons/menu/' + sideBarItem.icon)"
                alt="menu"
                style="height: 22px; width: 22px"
              />&nbsp;&nbsp;
              <!-- <span class="menu-title" slot="title">
                {{ sideBarItem.title }}
              </span> -->
              <span class="menu-title" :title="sideBarItem.title.length > 16 ? sideBarItem.title : ''">{{ sideBarItem.title | truncate(16, "...") }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
</section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";

export default {
  name: "SidebarDefault",
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [PermissionsHelper],
  data() {
    return {
      brandingInfo: {},
      activeLink: "62b55e9eb310d91964580ea5",
      menu: [],
      menuLoading: false,
      isOpen: "",
      routerValue: "",
      offCanvas: "",
      path: "",
      menuItems: [
        {
          title: "Company Documents",
          route: "/signup",
          icon: "company-document-2.svg",
          allowedUsers: ["GUEST"],
        },
      ],
      userMenu: [],
      defaultProps: {
        children: "children",
        title: "title",
      },
      adminMenu: true,
      userType: {},
      permissionsList: [],
      staticPaths: [
        {
          children: [],
          _id: "62b55e9eb310d91964580ea0",
          title: "Create Document",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "create_document.svg",
          route_id: "documents/upload",
          staticMenu: true,
          permission: "createNewDocument",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea5",
          title: "Dashboard",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "dashboard_active.svg",
          route_id: "dashboard",
          staticMenu: true,
          permission: "dashboard",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea6",
          title: "Documents",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "documents_active.svg",
          route_id: "documents/all",
          staticMenu: true,
          permission: "documents",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea7",
          title: "Templates",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "templates_active.svg",
          route_id: "templates",
          staticMenu: true,
          permission: "documentTemplate",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea8",
          title: "Contacts",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "contact_active.svg",
          route_id: "contacts",
          staticMenu: true,
          permission: "contacts",
        },
        {
          children: [],
          _id: "62b55e9eb310d91964580ea9",
          title: "Workflows",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "workflow_active.svg",
          route_id: "workflows",
          staticMenu: true,
          permission: "workflows",
        },
        // {
        //   children: [],
        //   _id: "62b55e9eb310d91964580eaa",
        //   title: "Request Documents",
        //   feature: "OWNERROUTES",
        //   category: "OWNERROUTES",
        //   type: "Individual",
        //   icon: "request-document_active.svg",
        //   route_id: "request-documents/select-card-type",
        //   staticMenu: true,
        //   permission: "request-documents"
        // },
        {
          children: [],
          _id: "62b55e9eb310d91964580eab",
          title: "Approval Forms",
          feature: "OWNERROUTES",
          category: "OWNERROUTES",
          type: "Individual",
          icon: "ApprovalForms.svg",
          route_id: "approval-forms",
          staticMenu: true,
          permission: "approval-forms"
        }
      ],
      planDetails: {},
      isHorMenu:true,
    };
  },
  computed: {
    ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
    ...mapGetters("navigationOpen", ["getIsOpen", "getIsCollapse"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserMenu",
      "getActiveWorkspace",
      "getUserType",
      "getPermissionList",
      "getPermissions",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
    ]),
    ...mapGetters("company", ["getContactLoginSlug","getUpdateCompany","getCompanyDetails"]),
    isCollapse: {
      get() {
        return this.getIsOpen;
      },
      set(isCollapse) {
        this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
          root: true,
        });
      },
    },
    getCurrentRouteName() {
      if (
        this.$router &&
        this.$router.currentRoute &&
        this.$router.currentRoute.name
      )
        return this.$router.currentRoute.name;
      else return null;
    },
    getUserMenuItems() {
      let userType = this.getActiveWorkspace ? this.userType.name : "";
      return this.menuItems.filter(
        (item) => item.allowedUsers.indexOf(userType) > -1
      );
    },
  },
  watch: {
    async "$route.path"() {
      if (this.$route && this.$route.path) {
        let name =
          this.$route.path.charAt(0) == "/"
            ? this.$route.path.slice(1)
            : this.$route.path;
        let ownerRoute = this.staticPaths.find((e) => e.route_id == name);
        if (ownerRoute && ownerRoute._id) {
          this.activeLink = ownerRoute._id;
        }
      }
    },
    getPaymentsHistory: async function () {
      if (
        this.getPaymentsHistory &&
        this.getPaymentsHistory.planFeatures &&
        this.getPaymentsHistory.planFeatures.length
      ) {
        this.planDetails =
          this.getPaymentsHistory &&
          this.getPaymentsHistory.planFeatures &&
          this.getPaymentsHistory.planFeatures[0];
      }
    },
    $route() {
      this.checkRoute();
    }
  },
  async mounted() {
      this.getMenuType();
    this.getPermissionsList();
    if (
      this.getAuthenticatedUser &&
      this.getAuthenticatedUser.activeRole &&
      this.getAuthenticatedUser.activeRole.userType_id
    ) {
      await this.$store.dispatch(
        "auth/getUserTypeById",
        this.getAuthenticatedUser.activeRole.userType_id
      );
    }
    if (
      this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
      this.getActiveWorkspace.company_id
    ) {
      this.userType = this.getUserType;
      this.getMenuList();
    } else {
      this.menu = this.staticPaths;
    }
    if (this.$route && this.$route.path) {
      let name =
        this.$route.path.charAt(0) == "/"
          ? this.$route.path.slice(1)
          : this.$route.path;
      let ownerRoute = this.staticPaths.find((e) => e.route_id == name);
      if (ownerRoute && ownerRoute._id) {
        this.activeLink = ownerRoute._id;
      }
    }
    this.checkRoute();
    await this.getCompanyInfo();
  },
  methods: {
    async getCompanyInfo() {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = {
            ...this.getCompanyDetails,
          };
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    checkRoute() {
      if (this.$route && (this.$route.name === "EntityDetailedEdit" || 
      this.$route.name === "EntityDetailedView" || 
      this.$route.name === "FormbuilderViewStep")) {
        this.isCollapse = false; // Set to true if the route matches
      } else {
        this.isCollapse = true; // Set to false otherwise
      }
    },
    expandSidebar() {
      if (this.$route && (this.$route.name === "EntityDetailedEdit" || 
      this.$route.name === "EntityDetailedView" || 
      this.$route.name === "FormbuilderViewStep")){
        this.isCollapse = true;
      }
      else{
        if (this.isCollapse) return; // Only collapse if it's currently expanded
      this.isCollapse = false;
      }
    },
    collapseSidebar() {
      if (this.$route && (this.$route.name === "EntityDetailedEdit" || 
      this.$route.name === "EntityDetailedView" || 
      this.$route.name === "FormbuilderViewStep")){
        this.isCollapse = false;
      }
      else{
      if (!this.isCollapse) return; // Only expand if it's currently collapsed
      this.isCollapse = true;
      }
    },
    async getMenuList() {
      this.menu = [];
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.activeRole &&
        this.getAuthenticatedUser.activeRole.userType_id
      ) {
        await this.$store.dispatch(
          "menuManagementV2/fetchMenuWithUserType",
          this.getAuthenticatedUser.activeRole.userType_id
        );
      }

      if (this.getMenu) {
        this.menu = this.getMenu
          ? this.getMenu.menu_list.map((e) => {
              if (e.entity_id && e.entity_id._id) {
                e.entity_id = e.entity_id._id;
              }

              if (e.workflow_id && e.workflow_id._id) {
                e.workflow_id = e.workflow_id._id;
              }
              return e;
            })
          : [];
      } else if (this.userType && this.userType.name === "OWNER") {
        this.menu = [];
        this.menu = this.staticPaths;
      } else {
        this.menu = [];
        this.menu = this.staticPaths;
      }
    },
    getMenuType(){
        if(this.getCompanyDetails && this.getCompanyDetails.menuIcon && this.getCompanyDetails.menuIcon == false)
       
        {
        // console.log("this.getCompanyDetails.menuIcon xvx",this.getCompanyDetails.menuIcon)
         this.isHorMenu = false; 
        }
        else{
          this.isHormenu = true;
        }
    },
    routeClick(data, isChild) {
      if (this.planDetails.diff) {
        if (data && data.feature === "ENTITIES") {
          this.activeLink = data._id;

          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/entity?group=${data.groups
                  }&routeType=subEntity&key=${Math.floor(
                    Math.random() * 100000
                  )}`,
              });
            } else {
              if (data.redirectToAdd) {
                this.$router.push({
                  path: `/entity/edit/${data.entity_id._id}?routeType=subEntity&filter=${data.filters
                    ? data.filters._id
                      ? data.filters._id
                      : data.filters
                    : ""
                    }&key=${Math.floor(Math.random() * 100000)}&addNew=true&isMenu=true&redirect=${data.redirectMenuItem && data.redirectMenuItem != undefined ? data.redirectMenuItem : ''}`
                })
              }
              else {
                this.$router.push({
                  path: `/entity/${data.entity_id._id
                    }?routeType=subEntity&filter=${data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                    }&key=${Math.floor(Math.random() * 100000)}&isMenu=true&redirect=${data.redirectMenuItem && data.redirectMenuItem != undefined ? data.redirectMenuItem : ''}`,
                });
              }
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/entity?group=${data.groups
                  }&routeType=entity&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              if (data.redirectToAdd) {
                this.$router.push({
                  path: `/entity/edit/${data.entity_id}?routeType=entity&filter=${data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                    }&key=${Math.floor(Math.random() * 100000)}&addNew=true&isMenu=true&redirect=${data.redirectMenuItem && data.redirectMenuItem != undefined ? data.redirectMenuItem : ''}`
                })
              }
              else {
                this.$router.push({
                  path: `/entity/${data.entity_id}?routeType=entity&filter=${data.filters
                      ? data.filters._id
                        ? data.filters._id
                        : data.filters
                      : ""
                    }&key=${Math.floor(Math.random() * 100000)}&isMenu=true&redirect=${data.redirectMenuItem && data.redirectMenuItem != undefined ? data.redirectMenuItem : ''}`,
                });
              }
            }
          }
        } else if (data && data.feature === "FORM_BUILDER") {
          let formBuilderKey = data?.formbuilder_id?.code;
          this.activeLink = data._id;
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/formBuilders?group=${
                  data.groups
                }&routeType=subFB&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/fbd/${formBuilderKey}?routeType=subFB&key=${Math.floor(
                  Math.random() * 100000
                )}&t=${data.title}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/formBuilders?group=${
                  data.groups
                }&routeType=FB&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/fbd/${formBuilderKey}?routeType=FB&key=${Math.floor(
                  Math.random() * 100000
                )}&t=${data.title}`,
              });
            }
          }
        } else if (data && data.feature === "WORKFLOWS") {
          this.activeLink = data._id;
          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/workflows?group=${
                  data.groups
                }&routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/workflow/${
                  data.workflow_id
                }?routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/workflows?group=${
                  data.groups
                }&routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            } else {
              this.$router.push({
                path: `/workflow/${
                  data.workflow_id
                }?routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                  data.title
                }`,
              });
            }
          }
        } else if (data && data.feature === "OWNERROUTES") {
          let currentRouteCheck =
            this.$route && this.$route.path && this.$route.path.substring(1);
          this.activeLink = data._id;
          if (data.route_id != currentRouteCheck) {
            if (data.route_id === "approval-forms"){
               this.$router.push({
                 path: `/forms/${data.route_id}`,
               });
            } else {
               this.$router.push({
                 path: `/${data.route_id}`,
               });
            }
          }
        } else if (data && data.feature === "FORM_TEMPLATE") {
          this.activeLink = data._id;

          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/template?group=${
                  data.groups
                }&routeType=subFT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/template?routeType=subFT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/template?group=${
                  data.groups
                }&routeType=FT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/template?routeType=FT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          }
        } else if (data && data.feature === "DOC_TEMPLATE") {
          this.activeLink = data._id;

          if (isChild) {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/templates?group=${
                  data.groups
                }&routeType=subDT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/templates?routeType=subDT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          } else {
            if (data.isGroup === "Groups") {
              this.$router.push({
                path: `/templates?group=${
                  data.groups
                }&routeType=DT&key=${Math.floor(Math.random() * 100000)}`,
              });
            } else {
              this.$router.push({
                path: `/templates?routeType=DT&key=${Math.floor(
                  Math.random() * 100000
                )}`,
              });
            }
          }
        } else if (data && data.feature === "ENTITY_VIEWS") {
          this.$router.push({
            path: `/entity-views/view/${
              data.entityviews_id
            }?routeType=entityviews&key=${Math.floor(Math.random() * 100000)}`,
          });
        } else if (data && data.feature === 'PAGE_DASHBOARD' && data.page_dashboard !== this.$route.params.dashboardId){
          this.$router.push(`/page-dashboard/${data.page_dashboard}`)
        } else {
          this.activeLink = "62b55e9eb310d91964580ea5";
          this.$router.push({
            path: `/dashboard`,
          });
        }
        this.$store.commit("navigationOpen/setCurrentMenu", data, {
          root: true,
        });
      } else {
        this.$message({
          message: "Your package has expired.",
          type: "error",
        });
        // this.$router.push("/expire")
      }
    },

    async getPermissionsList() {
      try {
        this.loading = true;
        await this.$store.dispatch("auth/getPermissions");
        if (this.getPermissionList) {
          this.permissionsList = this.getPermissionList;
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    checkPermissionsStatus(menuData) {
      if (
        this.isIndividualWorkspace(this.getActiveWorkspace) &&
        menuData &&
        (menuData.permission === "companyUsers" ||
          menuData.permission === "workflows" ||
          menuData.permission === "approval-forms")
      ) {
        return false;
      } else if (menuData && menuData.feature === "OWNERROUTES") {
        if (this.checkPermissionsCategory(menuData.permission)) {
          return true;
        } else if (this.checkPermissionByPermissionName(menuData.permission)) {
          return true;
        } else if (menuData.permission === "dashboard") {
          return true;
        } else {
          {
            return false;
          }
        }
      } else if (menuData && menuData.feature === "FORM_BUILDER") {
        // Check form builder permission permission
        return this.checkPermissionsCategory("formBuilder");
      } else if (menuData && menuData.feature === "ENTITIES") {
        // Check Entities permission
        return this.checkPermissionsCategory("entities");
      } else if (menuData && menuData.feature === "WORKFLOWS") {
        // Check workflow permission permission
        return this.checkPermissionsCategory("workflows");
        // return true;
      } else if (menuData && menuData.feature === "DOC_TEMPLATE") {
        // Check workflow permission permission
        // return this.checkPermissionsCategory("workflows");
        return true;
      } else if (menuData && menuData.feature === "FORM_TEMPLATE") {
        // Check workflow permission permission
        // return this.checkPermissionsCategory("workflows");
        return true;
      } else if (menuData && menuData.feature === "ENTITY_VIEWS") {
        // Entity view permissions need to be added
        return true;
      } else if(menuData && menuData.feature === 'PAGE_DASHBOARD'){
        return true
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.dashboard-view {
  &.mobile {
    .side-navigation-menu {
      &.is-open {
        transform: translate3d(0px, 0, 0);
      }

      transform: translate3d(-210px, 0, 0);
    }
  }
}

.side-navigation-menu {
  transform: translate3d(0px, 0, 0);
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  top: 60px;
  z-index: 999;
  background: white;
  border-right: 1px solid #f2f2f2;
  padding-top: 10px;
  width: 55px;
  min-height: calc(100vh - 70px);
  background-color: var(--sidemenu-background-color) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform : scale(1.0);

  .el-scrollbar__wrap {
    max-height: calc(100vh - 68px);
    overflow-y: auto;
  }

  .menu-item {
    list-style: none;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    span {
      display: inline-flex;

      img {
        max-width: 27px;
        max-height: 27px;
      }
    }

    .menu-title {
      display: none;
    }

    a {
      &.btn {
        background-color: transparent;
        padding: 0px;
        font-size: 2em;
        line-height: 1;
        transition: 0s all ease-in;
        color: #f754a2;
      }
    }
  }

  &.is-open {
    width: 220px;
    min-height: calc(100vh - 70px);


    .menu-item {
      display: flex;
      padding: 10px 15px;
      justify-content: flex-start;
      text-align: left;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.1em;

        &.btn {
          background-color: #f754a2;
          padding: 2px 10px;
          border-radius: 3px;

          .icon,
          .menu-title {
            color: #ffffff;
          }
        }
      }

      .menu-title {
        display: inline-flex;
        line-height: 30px;
        padding-left: 10px;
        color: #4c4b4e;
      }
    }
  }

  .menu-icon {
    width: 120px !important;
    height: 50px !important;
    visibility: initial !important;

    img {
      width: 30px;
    }
  }
}

.is-active {
  background: #ecf5ff;
}

.el-menu--collapse {
  .menu-icon {
    margin-right: 0 !important;
  }

  .el-tooltip {
    display: flex !important;
    align-items: center !important;
  }
}

.el-tooltip {
  padding: 0 0px;
}

.el-menu-item{
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  display: flex;
  align-items: center;
}
</style>

<style scoped>
.default-style{
  .el-submenu__title {
    font-size: 14px;
    font-weight: 500 !important;
    padding: 0 20px;
  }

  .el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: var(--primary-color) !important;
    color: var(--primary-contrast-color) !important;
  }

  .el-submenu__title:hover {
    background-color: var(--primary-color) !important;
    color: var(--primary-contrast-color) !important;
  }

  .el-menu-item.is-active {
    background-color: var(--primary-color) !important;
    color: var(--primary-contrast-color) !important;
  }
}

.inverted-color-style{

  .el-menu-item {
    font-size: 14px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--sidemenu-background-color) !important;
    color: var(--sidemenu-text-color) !important;
  }

  .el-submenu__title {
    font-size: 14px;
    font-weight: 500 !important;
    padding: 0 20px;
    cursor: pointer;
    -webkit-transition: border-color .3s, background-color .3s, color .3s;
    transition: border-color .3s, background-color .3s, color .3s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: var(--sidemenu-background-color) !important;
    color: var(--sidemenu-text-color) !important;
}

  .el-menu-item:focus, .el-menu-item:hover {
    outline: 0;
    background-color: var(--sidemenu-text-color) !important;
    color: var(--sidemenu-background-color) !important;
  }

  .el-submenu__title:hover {
    background-color: var(--sidemenu-text-color) !important;
    color: var(--sidemenu-background-color) !important;
  }

  .el-menu-item.is-active {
    background-color: var(--sidemenu-text-color) !important;
    color: var(--sidemenu-background-color) !important;
  }

  .el-menu-item-group__title {
    padding: 7px 0 7px 20px;
    line-height: normal;
    font-size: 12px;
    color: var(--sidemenu-text-color) !important;
    background-color: var(--sidemenu-background-color) !important;
  }
}
</style>
