<template>
  <section class="dashboard-view" :class="{ 'mobile': getIsMobile, 'is-open': isCollapse }">
    <el-row>
      <el-col :span="24">
        <TopbarDefault @open:sidebar="sidebarOpen"></TopbarDefault>
      </el-col>
    </el-row>
    <template v-if="isHorMenu">
    <el-row >
      <el-col :span="24">
          <HorizontalMenu />
      </el-col>
    </el-row>
    <br/><br/>
    <el-row type="flex" justify="center">
      <el-col :xl="24" :lg="24" :md="24">
        <div class="canvas">
          <el-link
          icon="el-icon-link"
          style="font-size: 24px; float: right; margin-top: 20px;"
          :underline="false"
          class="mt-"
          @click="openViewConfigPage"
        ></el-link>
          <main class="main-dashboard-view" id="MainLayout">
            <router-view :layout.sync="layout" />
          </main>
        </div>
      </el-col>
    </el-row>
  </template>
  <template v-else>
    <el-row type="flex" justify="center">
      <el-col :xl="24" :lg="24" :md="24">
        <div :class="!getIsOpen ? 'canvas' : 'offCanvas'">
          <el-link
                icon="el-icon-link"
                style="font-size: 24px; float: right; margin-top: 20px;"
                :underline="false"
                class="mt-"
                @click="openViewConfigPage"
              ></el-link>
          <main class="main-dashboard-view main-dashboard-view-with-sidebar " id="MainLayout">
            <router-view :layout.sync="layout" />
          </main>
        </div>
      </el-col>
    </el-row>
    <div  class="bg-canvas" v-on:click="closeLeftSideBar()">
    </div>
    <SidebarDefault :open="sidebarStatus" class="left-side-bar" />
</template>
<div>
    <el-drawer
        title="Privacy Link"
        :visible.sync="copyModal"
        :direction="'rtl'"
        :with-header="true"
        :size="getIsMobile ? '50%' : '20%'"
      >
      <div style="padding:15px;">
        <span >choose the link type</span>
       <el-card style="width: 100%; height: 50px; display: flex; align-items: center;">
      
         <br />
          <el-radio-group v-model="link" style="margin-bottom :20px">
            <el-row>
             <el-radio label="Public"></el-radio>
             <el-radio label="Private"></el-radio>
              </el-row>
           </el-radio-group>
           </el-card>
           <div v-if="link=='Public'">
           <!-- <div style="display: flex" class="mt-1">
    <el-card style="width: 100%; height: 50px; display: flex; align-items: center;">
      <span style="margin-right: 116px; font-style: bold;">No sign-in</span>
        <el-checkbox v-model="isChecked"></el-checkbox>
        <span style="font-size: 11px;">Users do not have their own profile</span>
    </el-card>
  </div>
  <div style="display: flex" class="mt-1">
    <el-card style="width: 100%; height: 50px; display: flex; align-items: center;">
      <span style="margin-right: 134px; font-style: bold;">Anyone</span>
        <el-checkbox v-model="isChecked"></el-checkbox>
        <span style="font-size: 11px;">Users do not have their own profile</span>
    </el-card>
  </div> -->
  <div style="display: flex" class="mt-1">
    <el-input
      size="mini"
      v-model="publicLink"
      ref="publicLinkRef"
      v-on:focus="$event.target.select()"
    ></el-input>
    <el-button
      type="danger"
      icon="el-icon-copy-document"
      class="copy-btn"
      size="mini"
      @click="copyLinkToClipboard('publicLinkRef')"
    ></el-button>
  </div>
  </div>
  <div v-if="link=='Private'">
            <!-- <div style="display: flex" class="mt-1">
              <el-card style="width: 100%; height: 50px; display: flex; align-items: center;">
                <span style="margin-right: 70px; font-style: bold;">Company Users </span>
                  <el-checkbox v-model="isChecked"></el-checkbox>
                  <span style="font-size: 11px;">Members of the company can sing in </span>
                </el-card>
            </div> -->
            <div style="display: flex" class="mt-1">
    <el-input
      size="mini"
      v-model="privateLink"
      ref="privateLinkRef"
      v-on:focus="$event.target.select()"
    ></el-input>
    <el-button
      type="danger"
      icon="el-icon-copy-document"
      class="copy-btn"
      size="mini"
      @click="copyLinkToClipboard('privateLinkRef')"
    ></el-button>
            </div>
  </div>
       </div>
</el-drawer>
  </div>
  </section>
 
</template>

<script>
import { mapGetters } from "vuex";
import TopbarDefault from "../layouts/TopbarDefault";
import SidebarDefault from "../layouts/SidebarDefault";
import HorizontalMenu from "../layouts/HorizontalMenu";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import appConfig from "@/config/app";
export default {
  name: "home",
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // this.$router.listen((newLocation) => {console.log(newLocation);})
    // console.log(this.$route);
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
    }
  },
  components: {
    TopbarDefault,
    SidebarDefault,
    HorizontalMenu,
  },
  mixins: [MobileRelatedHelper],
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("menuManagementV2", [
      "getMenu",
      "getMenuManagementAddStatus",
      "getMenuManagementUpdateStatus",
    ]),
    ...mapGetters("auth",[
      "getActiveWorkspace","getAuthenticationDetails","getAuthenticatedSSOToken", "getUserTypeList"
    ]),
    ...mapGetters("company", ["getContactLoginSlug","getUpdateCompany","getCompanyDetails"]),
    ...mapGetters("sso",["getAuthenticatedSSOToken"]),
    isCollapse: {
      get() {
        return this.getIsOpen;
      },
      set(isCollapse) {
        this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
          root: true,
        });
      },
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
  },
  async mounted() {
    await this.getCompanyInfo();
    let queryParams = Object.keys(this.$route.query).length > 0 ? `?${new URLSearchParams(this.$route.query).toString()}` : '';
    let url = appConfig.APP_URL
      this.publicLink = `${url}/if/${this.getAuthenticatedSSOToken}${this.$route.path}${queryParams}`;
      this.privateLink = `${url}/if/${this.getAuthenticatedSSOToken}private${this.$route.path}${queryParams}`;
      this.getMenuType();
  },
  data() {
    return {
      layout: "div",
      offCanvas: true,
      sidebarStatus: false,
      windowWidth: 1500,
      isHorMenu:false,
      copyModal: false,
      getlink:'',
      activeTab:'first',
      publicLink: '',
      privateLink: '',
      isChecked: false,
      link:'',
      dashboard_role: [],
    };
  },
  methods: {
    async getCompanyInfo() {
      // this.loading = true;
      try {
        await this.$store.dispatch(
          "company/fetchCompany",
          this.getActiveWorkspace.company_id
        );
        if (this.getCompanyDetails) {
          this.loading = false;
          this.brandingInfo = {...this.getCompanyDetails};
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    sidebarOpen(status) {
      this.sidebarStatus = status;
    },
    closeLeftSideBar() {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
    },
    getMenuType(){
        if(this.getCompanyDetails && this.getCompanyDetails.menuType&&this.getCompanyDetails.menuType=='Horizontal')
        {
         this.isHorMenu = true; 
        }
        else{
          this.isHormenu = false;
        }
    },
    openViewConfigPage() {
      this.copyModal = true;
    },
    copyLinkToClipboard(refName) {
    this.$refs[refName].focus();
    document.execCommand('copy');
    this.$message.success('Link copied to clipboard');
  },
  },
  watch: {
    '$route'(to) {
      let url = appConfig.APP_URL
      let queryParams = Object.keys(this.$route.query).length > 0 ? `?${new URLSearchParams(this.$route.query).toString()}` : '';
      this.publicLink = `${url}/if/${this.getAuthenticatedSSOToken}${to.path}${queryParams}`;
      this.privateLink = `${url}/if/${this.getAuthenticatedSSOToken}private${to.path}${queryParams}`;
    },
  }
};
</script>

<style lang="scss">
.dashboard-view {
  &.mobile {
    &.is-open {
      // padding-left: 100px;
      .bg-canvas {
        display: block;
      }
    }
    .main-dashboard-view {
      padding-left: 30px;
      // padding-right: 30px;
    }
    .left-side-bar {
      position: absolute;
      z-index: 11000;
    }
    .offCanvas {
      margin-left: 0px;
      // background: rgba(0, 0, 0, .025);
    }
    .bg-canvas {
      background: rgba(0, 0, 0, 0.5);
      z-index: 10000;
      width: 100vw;
      height: 100vh;
      position: absolute;
      border: 1px solid red;
      top: 0;
      left: 0;
      display: none;
    }
  }
}
.dashboard-view {
  background: #fafafa;
  min-height: 100vh;
  z-index: 999;
  // min-height: calc(100vh - 68px);
  .main-dashboard-view {
    margin-top: 50px;
    padding-top: 70px !important;
    padding-left: 100px;
    padding-right: 30px;
  }
  .main-dashboard-view-with-sidebar{
    margin-top: 50px !important;
    padding-top: 30px !important;
    padding-left: 100px !important;
    padding-right: 30px !important;
  }
  .edit-new-template-view {
    padding-left: 0;
  }
  .canvas {
    transition: all 0.2s ease-in-out;
  }
  .offCanvas {
    margin-left: 150px;
    transition: all 0.2s ease-in-out;
  }
  .icon-link {
  font-size: 24px;
  float: right;
}
.el-input--mini .el-input__inner {
  width:95%
}
}
.el-drawer__wrapper .el-drawer__header {
    background-color: #1B487E;
    border-bottom: none;
    padding-bottom: 0.2em;
    padding-top: 5px;
    color: #ffffff;
    font-size: 20px;
    font-family: "Inter", sans-serif;
    letter-spacing: 0.5px;
}
.el-drawer__header {
  margin-bottom: 1px;
    padding: 20px 20px 0;
}

</style>
