<template>
  <section v-if="getmenuInEntity">
      <el-menu
      mode="horizontal" 
      :default-active="activeLink"
      active-text-color="#F754A2"
      :class="{ 'default-style': !brandingInfo.styled_sidemenu, 'inverted-color-style': brandingInfo.styled_sidemenu }"
      class="horizontal-navbar"
    >
      <template v-for="sideBarItem,i in mainMenu">
        <template v-if="sideBarItem.children && sideBarItem.children.length">
          <el-submenu :key="sideBarItem._id" :index="sideBarItem._id">
            <template slot="title">
              <img
                v-if=" getCompanyDetails && getCompanyDetails.menuIcon"
                :src="require('@/assets/img/icons/menu/' + sideBarItem.icon)"
                alt="menu"
                style="height: 22px; width: 22px"
              />&nbsp;&nbsp;
              <!-- <span slot="title" >{{ sideBarItem.title }}</span> -->
              <span :title="sideBarItem.title.length > 15 ? sideBarItem.title : ''">{{ sideBarItem.title | truncate(15, "...") }}</span>
            </template>

            <el-menu-item-group>
              <template v-for="sideBarSubItem in sideBarItem.children">
                <template v-if="sideBarSubItem.type =='Child'">
                <el-menu-item
                  :key="sideBarSubItem._id"
                  :index="sideBarSubItem._id"
                  @click="routeClick(sideBarSubItem, true)"
                  v-if="checkPermissionsStatus(sideBarSubItem)"
                >
                  <img
                  v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                    :src="
                    require('@/assets/img/icons/menu/' +
                      sideBarSubItem.icon)
                  "
                    alt="menu"
                    style="height: 22px; width: 22px"
                  />
                  &nbsp;&nbsp;
                  <!-- <span
                    class="menu-title"
                  >{{ sideBarSubItem.title }}</span> -->
                  <span :title="sideBarSubItem.title.length > 15 ? sideBarSubItem.title : ''">{{ sideBarSubItem.title | truncate(15, "...") }}</span>
                </el-menu-item>
              </template>
              <template v-if="sideBarSubItem.type =='SubParent'">
                <el-submenu :key="sideBarSubItem._id" :index="sideBarSubItem._id">
            <template slot="title">
              <img
              v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                    :src="
                    require('@/assets/img/icons/menu/' +
                      sideBarSubItem.icon)
                  "
                    alt="menu"
                    style="height: 22px; width: 22px"
                  />&nbsp;&nbsp;
              <!-- <span  class="menu-title" slot="title">{{ sideBarSubItem.title }}</span> -->
              <span :title="sideBarSubItem.title.length > 15 ? sideBarSubItem.title : ''">{{ sideBarSubItem.title | truncate(15, "...") }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="sideBarSubSubItem in sideBarSubItem.children">
                <el-menu-item
                  :key="sideBarSubSubItem._id"
                  :index="sideBarSubSubItem._id"
                  @click="routeClick(sideBarSubSubItem, true)"
                  v-if="checkPermissionsStatus(sideBarSubSubItem)"
                >
                  <img
                  v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                    :src="
                    require('@/assets/img/icons/menu/' +
                      sideBarSubSubItem.icon)
                  "
                    alt="menu"
                    style="height: 22px; width: 22px"
                  />
                  &nbsp;&nbsp;
                  <!-- <span
                    class="menu-title"
                  >{{ sideBarSubSubItem.title }}</span> -->
                  <span :title="sideBarSubSubItem.title.length > 15 ? sideBarSubSubItem.title : ''">{{ sideBarSubSubItem.title | truncate(15, "...") }}</span>
                </el-menu-item>
              </template>
            </el-menu-item-group>
            </el-submenu>
              </template>
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item
            :key="i + sideBarItem._id"
            :index="sideBarItem._id"
            @click="routeClick(sideBarItem, false)"
            v-if="checkPermissionsStatus(sideBarItem)"
          >
            <img
            v-if="getCompanyDetails && getCompanyDetails.menuIcon"
              :src="require('@/assets/img/icons/menu/' + sideBarItem.icon)"
              alt="menu"
              style="height: 22px; width: 22px"
            />&nbsp;&nbsp;
            <!-- <span class="menu-title" slot="title">
              {{
              sideBarItem.title
              }} 
            </span> -->
            <span class="menu-title" :title="sideBarItem.title.length > 15 ? sideBarItem.title : ''">{{ sideBarItem.title | truncate(15, "...") }}</span>
          </el-menu-item>
        </template>
      </template>
      <template v-if="extraMenu.length">
       
        <el-submenu index="extramenu" >
          <template slot="title">
          <span class="menu-title" ><b>...</b></span>
        </template>
          <el-menu-item-group>
              <template v-for="sideBarItem,i in extraMenu">
                <template v-if="sideBarItem.children && sideBarItem.children.length">
          <el-submenu :key="sideBarItem._id" :index="sideBarItem._id">
            <template slot="title">
              <img
                v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                :src="require('@/assets/img/icons/menu/' + sideBarItem.icon)"
                alt="menu"
                style="height: 22px; width: 22px"
              />&nbsp;&nbsp;
              <!-- <span slot="title" >{{ sideBarItem.title }}</span> -->
              <span class="menu-title" :title="sideBarItem.title.length > 15 ? sideBarItem.title : ''">{{ sideBarItem.title | truncate(15, "...") }}</span>
            </template>

            <el-menu-item-group>
              <template v-for="sideBarSubItem in sideBarItem.children">
                <template v-if="sideBarSubItem.type =='Child'">
                <el-menu-item
                  :key="sideBarSubItem._id"
                  :index="sideBarSubItem._id"
                  @click="routeClick(sideBarSubItem, true)"
                  v-if="checkPermissionsStatus(sideBarSubItem)"
                >
                  <img
                  v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                    :src="
                    require('@/assets/img/icons/menu/' +
                      sideBarSubItem.icon)
                  "
                    alt="menu"
                    style="height: 22px; width: 22px"
                  />
                  &nbsp;&nbsp;
                  <!-- <span
                    class="menu-title"
                  >{{ sideBarSubItem.title }}</span> -->
                  <span class="menu-title" :title="sideBarSubItem.title.length > 15 ? sideBarSubItem.title : ''">{{ sideBarSubItem.title | truncate(15, "...") }}</span>
                </el-menu-item>
              </template>
              <template v-if="sideBarSubItem.type =='SubParent'">
                <el-submenu :key="sideBarSubItem._id" :index="sideBarSubItem._id">
            <template slot="title">
              <img
              v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                    :src="
                    require('@/assets/img/icons/menu/' +
                      sideBarSubItem.icon)
                  "
                    alt="menu"
                    style="height: 22px; width: 22px"
                  />&nbsp;&nbsp;
              <!-- <span  class="menu-title" slot="title">{{ sideBarSubItem.title }}</span> -->
              <span class="menu-title" :title="sideBarSubItem.title.length > 15 ? sideBarSubItem.title : ''">{{ sideBarSubItem.title | truncate(15, "...") }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="sideBarSubSubItem in sideBarSubItem.children">
                <el-menu-item
                  :key="sideBarSubSubItem._id"
                  :index="sideBarSubSubItem._id"
                  @click="routeClick(sideBarSubSubItem, true)"
                  v-if="checkPermissionsStatus(sideBarSubSubItem)"
                >
                  <img
                  v-if="getCompanyDetails && getCompanyDetails.menuIcon"
                    :src="
                    require('@/assets/img/icons/menu/' +
                      sideBarSubSubItem.icon)
                  "
                    alt="menu"
                    style="height: 22px; width: 22px"
                  />
                  &nbsp;&nbsp;
                  <!-- <span
                    class="menu-title"
                  >{{ sideBarSubSubItem.title }}</span> -->
                  <span class="menu-title" :title="sideBarSubSubItem.title.length > 15 ? sideBarSubSubItem.title : ''">{{ sideBarSubSubItem.title | truncate(15, "...") }}</span>
                  
                </el-menu-item>
              </template>
            </el-menu-item-group>
            </el-submenu>
              </template>
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item
            :key="i + sideBarItem._id"
            :index="sideBarItem._id"
            @click="routeClick(sideBarItem, false)"
            v-if="checkPermissionsStatus(sideBarItem)"
          >
            <img
            v-if="getCompanyDetails && getCompanyDetails.menuIcon"
              :src="require('@/assets/img/icons/menu/' + sideBarItem.icon)"
              alt="menu"
              style="height: 22px; width: 22px"
            />&nbsp;&nbsp;
            <!-- <span class="menu-title" slot="title">
              {{
              sideBarItem.title
              }} 
            </span> -->
            <span class="menu-title" :title="sideBarItem.title.length > 15 ? sideBarItem.title : ''">{{ sideBarItem.title | truncate(15, "...") }}</span>
          </el-menu-item>
        </template>
               
              </template>
            </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
// import { } from '@ant-design/icons-vue';
export default {
name: "HorizontalMenu",
props: {
  // open: {
  //   type: Boolean,
  // }
},
mixins: [PermissionsHelper],
data() {
  return {
    brandingInfo: {},
    activeLink: "62b55e9eb310d91964580ea5",
    menu: [],
    isHorMenu: true,
    menuLoading: false,
    isOpen: "",
    routerValue: "",
    offCanvas: "",
    path: "",
    menuItems: [
      {
        title: "Company Documents",
        route: "/signup",
        icon: "company-document-2.svg",
        allowedUsers: ["GUEST"],
      },
    ],
    userMenu: [],
    defaultProps: {
      children: "children",
      title: "title",
    },
    adminMenu: true,
    userType: {},
    permissionsList: [],
    staticPaths: [
      {
        children: [],
        _id: "62b55e9eb310d91964580ea0",
        title: "Create Document",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "create_document.svg",
        route_id: "documents/upload",
        staticMenu: true,
        permission: "createNewDocument",
      },
      {
        children: [],
        _id: "62b55e9eb310d91964580ea5",
        title: "Dashboard",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "dashboard_active.svg",
        route_id: "dashboard",
        staticMenu: true,
        permission: "dashboard",
      },
      {
        children: [],
        _id: "62b55e9eb310d91964580ea6",
        title: "Documents",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "documents_active.svg",
        route_id: "documents/all",
        staticMenu: true,
        permission: "documents",
      },
      {
        children: [],
        _id: "62b55e9eb310d91964580ea7",
        title: "Templates",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "templates_active.svg",
        route_id: "templates",
        staticMenu: true,
        permission: "documentTemplate",
      },
      {
        children: [],
        _id: "62b55e9eb310d91964580ea8",
        title: "Contacts",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "contact_active.svg",
        route_id: "contacts",
        staticMenu: true,
        permission: "contacts",
      },
      {
        children: [],
        _id: "62b55e9eb310d91964580ea9",
        title: "Workflows",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "workflow_active.svg",
        route_id: "workflows",
        staticMenu: true,
        permission: "workflows",
      },
      // {
      //   children: [],
      //   _id: "62b55e9eb310d91964580eaa",
      //   title: "Request Documents",
      //   feature: "OWNERROUTES",
      //   category: "OWNERROUTES",
      //   type: "Individual",
      //   icon: "request-document_active.svg",
      //   route_id: "request-documents/select-card-type",
      //   staticMenu: true,
      //   permission: "request-documents",
      // },
      {
        children: [],
        _id: "62b55e9eb310d91964580eab",
        title: "Approval Forms",
        feature: "OWNERROUTES",
        category: "OWNERROUTES",
        type: "Individual",
        icon: "ApprovalForms.svg",
        route_id: "approval-forms",
        staticMenu: true,
        permission: "approval-forms"
      }
    ],
    planDetails: {},
    device:'SM',
    // ItemCount:{"1200":10,"1000":7,"900":6,"850":5,"600":4,"SM":1},
    menuItemCount:7,
    mainMenu : [],
    extraMenu : [],
  };
},
computed: {
  ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
  ...mapGetters("navigationOpen", ["getIsOpen", "getIsCollapse"]),
  ...mapGetters("auth", [
    "getAuthenticatedUser",
    "getUserMenu",
    "getActiveWorkspace",
    "getUserType",
    "getPermissionList",
    "getPermissions",
  ]),
  ...mapGetters("menuManagementV2", [
    "getMenu",
    "getMenuManagementAddStatus",
    "getMenuManagementUpdateStatus",
  ]),
  ...mapGetters("auth", ["getActiveWorkspace"]),
  ...mapGetters("company", [
    "getContactLoginSlug",
    "getUpdateCompany",
    "getCompanyDetails",
  ]),
  // isCollapse: {
  //   get() {
  //     return this.getIsOpen;
  //   },
  //   set(isCollapse) {
  //     this.$store.commit("navigationOpen/setIsOpen", isCollapse, {
  //       root: true
  //     });
  //   }
  // },
  getCurrentRouteName() {
    if (
      this.$router &&
      this.$router.currentRoute &&
      this.$router.currentRoute.name
    )
      return this.$router.currentRoute.name;
    else return null;
  },
  getUserMenuItems() {
    let userType = this.getActiveWorkspace ? this.userType.name : "";
    return this.menuItems.filter(
      (item) => item.allowedUsers.indexOf(userType) > -1
    );
  },
  getmenuInEntity() {
    if (
      this.$route.matched[1].path.toString() !== "/entity/:entity_id/edit"
    ) {
      document.getElementById("MainLayout").style.paddingTop = "80px";
      return true;
    }
    document.getElementById("MainLayout").style.paddingTop = "30px";
    return false;
  },
},
watch: {
  async "$route.path"() {
    if (this.$route && this.$route.path) {
      let name =
        this.$route.path.charAt(0) == "/"
          ? this.$route.path.slice(1)
          : this.$route.path;
      let ownerRoute = this.staticPaths.find((e) => e.route_id == name);
      if (ownerRoute && ownerRoute._id) {
        this.activeLink = ownerRoute._id;
      }
    }
  },
  getPaymentsHistory: async function () {
    if (
      this.getPaymentsHistory &&
      this.getPaymentsHistory.planFeatures &&
      this.getPaymentsHistory.planFeatures.length
    ) {
      this.planDetails =
        this.getPaymentsHistory &&
        this.getPaymentsHistory.planFeatures &&
        this.getPaymentsHistory.planFeatures[0];
    }
  }
},
async mounted() {
  await this.getCompanyInfo();
  this.getMenuType();
  this.getPermissionsList();
  if (
    this.getAuthenticatedUser &&
    this.getAuthenticatedUser.activeRole &&
    this.getAuthenticatedUser.activeRole.userType_id
  ) {
    await this.$store.dispatch(
      "auth/getUserTypeById",
      this.getAuthenticatedUser.activeRole.userType_id
    );
  }
  if (
    this.isWorkspaceIsBusiness(this.getActiveWorkspace) &&
    this.getActiveWorkspace.company_id
  ) {
    this.userType = this.getUserType;
    await this.getMenuList();
  } else {
    this.menu = this.staticPaths;
  }
  this.updateScreenWidth();
  this.onScreenResize();
//   console.log("menu",this.menu)
//   let mql = window.screen.width

//   switch(true){
//   case  mql>1300:
//     this.menuItemCount=9
//     break;
//   case  mql>1200:
//     this.menuItemCount=8
//     break;
//     case mql>=1100:
//     this.menuItemCount = 7
//     break;
//   case mql>=1000:
//     this.menuItemCount = 6
//     break;
//   case mql>900:
//     this.menuItemCount = 5
//     break;
//   case mql>600:
//     this.menuItemCount = 4
//     break;
//   case mql>200:
//     this.menuItemCount = 2
//     break;
//     case mql>100:
//     this.menuItemCount = 1
//     break;
// }
// if(this.menu.length > this.menuItemCount){
//   this.mainMenu = this.menu.slice(0,this.menuItemCount)
//   this.extraMenu = this.menu.slice(this.menuItemCount, this.menu.length)
// }
// else{
//   this.mainMenu = []
//   this.mainMenu = [...this.mainMenu,...this.menu]
// }
  if (this.$route && this.$route.path) {
    let name =
      this.$route.path.charAt(0) == "/"
        ? this.$route.path.slice(1)
        : this.$route.path;
    let ownerRoute = this.staticPaths.find((e) => e.route_id == name);
    if (ownerRoute && ownerRoute._id) {
      this.activeLink = ownerRoute._id;
    }
  }
  // console.log("Media Screen size",window.screen,window.screen.width);
},
methods: {
  async getCompanyInfo() {
    // this.loading = true;
    try {
      await this.$store.dispatch(
        "company/fetchCompany",
        this.getActiveWorkspace.company_id
      );
      if (this.getCompanyDetails) {
        this.loading = false;
        this.brandingInfo = this.getCompanyDetails;

      }
    } catch (e) {
      this.loading = false;
      this.$notify.error({
        title: "Error",
        message: e,
      });
    }
  },
  async getMenuList() {
    this.menu = [];
    if (
      this.getAuthenticatedUser &&
      this.getAuthenticatedUser.activeRole &&
      this.getAuthenticatedUser.activeRole.userType_id
    ) {
      await this.$store.dispatch(
        "menuManagementV2/fetchMenuWithUserType",
        this.getAuthenticatedUser.activeRole.userType_id
      );
    }

    if (this.getMenu) {
      this.menu = this.getMenu
        ? this.getMenu.menu_list.map((e) => {
            if (e.entity_id && e.entity_id._id) {
              e.entity_id = e.entity_id._id;
            }

            if (e.workflow_id && e.workflow_id._id) {
              e.workflow_id = e.workflow_id._id;
            }
            return e;
          })
        : [];
    } else if (this.userType && this.userType.name === "OWNER") {
      this.menu = [];
      this.menu = this.staticPaths;
    } else {
      this.menu = [];
      this.menu = this.staticPaths;
    }
  },
  getMenuType() {
    if (
      this.getCompanyDetails &&
      this.getCompanyDetails.menuIcon &&
      this.getCompanyDetails.menuIcon == false
    ) {
      this.isHorMenu = false;
    } else {
      this.isHormenu = true;
    }
  },
  routeClick(data, isChild) {
    if (this.planDetails.diff) {
      if (data && data.feature === "ENTITIES") {
        this.activeLink = data._id;

        if (isChild) {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/entity?group=${
                data.groups
              }&routeType=subEntity&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          } else {
            this.$router.push({
              path: `/entity/${
                data.entity_id._id
              }?routeType=subEntity&filter=${
                data.filters
                  ? data.filters._id
                    ? data.filters._id
                    : data.filters
                  : ""
              }&key=${Math.floor(Math.random() * 100000)}`,
            });
          }
        } else {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/entity?group=${
                data.groups
              }&routeType=entity&key=${Math.floor(Math.random() * 100000)}`,
            });
          } else {
            this.$router.push({
              path: `/entity/${data.entity_id}?routeType=entity&filter=${
                data.filters
                  ? data.filters._id
                    ? data.filters._id
                    : data.filters
                  : ""
              }&key=${Math.floor(Math.random() * 100000)}`,
            });
          }
        }
      } else if (data && data.feature === "FORM_BUILDER") {
        let formBuilderKey = data?.formbuilder_id?.code;
        this.activeLink = data._id;
        if (isChild) {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/formBuilders?group=${
                data.groups
              }&routeType=subFB&key=${Math.floor(Math.random() * 100000)}&t=${
                data.title
              }`,
            });
          } else {
            this.$router.push({
              path: `/fbd/${formBuilderKey}?routeType=subFB&key=${Math.floor(
                Math.random() * 100000
              )}&t=${data.title}`,
            });
          }
        } else {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/formBuilders?group=${
                data.groups
              }&routeType=FB&key=${Math.floor(Math.random() * 100000)}&t=${
                data.title
              }`,
            });
          } else {
            this.$router.push({
              path: `/fbd/${formBuilderKey}?routeType=FB&key=${Math.floor(
                Math.random() * 100000
              )}&t=${data.title}`,
            });
          }
        }
      } else if (data && data.feature === "WORKFLOWS") {
        this.activeLink = data._id;
        if (isChild) {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/workflows?group=${
                data.groups
              }&routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                data.title
              }`,
            });
          } else {
            this.$router.push({
              path: `/workflow/${
                data.workflow_id
              }?routeType=subWF&key=${Math.floor(Math.random() * 100000)}&t=${
                data.title
              }`,
            });
          }
        } else {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/workflows?group=${
                data.groups
              }&routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                data.title
              }`,
            });
          } else {
            this.$router.push({
              path: `/workflow/${
                data.workflow_id
              }?routeType=WF&key=${Math.floor(Math.random() * 100000)}&t=${
                data.title
              }`,
            });
          }
        }
      } else if (data && data.feature === "OWNERROUTES") {
        let currentRouteCheck =
          this.$route && this.$route.path && this.$route.path.substring(1);
        this.activeLink = data._id;
        if (data.route_id != currentRouteCheck) {
          if (data.route_id === "approval-forms"){
             this.$router.push({
               path: `/forms/${data.route_id}`,
             });
          } else {
             this.$router.push({
               path: `/${data.route_id}`,
             });
          }
        }
      } else if (data && data.feature === "FORM_TEMPLATE") {
        this.activeLink = data._id;

        if (isChild) {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/template?group=${
                data.groups
              }&routeType=subFT&key=${Math.floor(Math.random() * 100000)}`,
            });
          } else {
            this.$router.push({
              path: `/template?routeType=subFT&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          }
        } else {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/template?group=${
                data.groups
              }&routeType=FT&key=${Math.floor(Math.random() * 100000)}`,
            });
          } else {
            this.$router.push({
              path: `/template?routeType=FT&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          }
        }
      } else if (data && data.feature === "DOC_TEMPLATE") {
        this.activeLink = data._id;

        if (isChild) {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/templates?group=${
                data.groups
              }&routeType=subDT&key=${Math.floor(Math.random() * 100000)}`,
            });
          } else {
            this.$router.push({
              path: `/templates?routeType=subDT&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          }
        } else {
          if (data.isGroup === "Groups") {
            this.$router.push({
              path: `/templates?group=${
                data.groups
              }&routeType=DT&key=${Math.floor(Math.random() * 100000)}`,
            });
          } else {
            this.$router.push({
              path: `/templates?routeType=DT&key=${Math.floor(
                Math.random() * 100000
              )}`,
            });
          }
        }
      }  else if (data && data.feature === "ENTITY_VIEWS") {
        this.$router.push({
          path: `/entity-views/view/${
            data.entityviews_id
          }?routeType=entityviews&key=${Math.floor(Math.random() * 100000)}`,
        });
      }else {
        this.activeLink = "62b55e9eb310d91964580ea5";
        this.$router.push({
          path: `/dashboard`,
        });
      }
      this.$store.commit("navigationOpen/setCurrentMenu", data, {
        root: true,
      });
    } else {
      this.$message({
        message: "Your package has expired.",
        type: "error",
      });
      // this.$router.push("/expire")
    }
  },

  async getPermissionsList() {
    try {
      this.loading = true;
      await this.$store.dispatch("auth/getPermissions");
      if (this.getPermissionList) {
        this.permissionsList = this.getPermissionList;
      }
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
  },
  checkPermissionsStatus(menuData) {
    if (
      this.isIndividualWorkspace(this.getActiveWorkspace) &&
      menuData &&
      (menuData.permission === "companyUsers" ||
        menuData.permission === "workflows")
    ) {
      return false;
    } else if (menuData && menuData.feature === "OWNERROUTES") {
      if (this.checkPermissionsCategory(menuData.permission)) {
        return true;
      } else if (this.checkPermissionByPermissionName(menuData.permission)) {
        return true;
      } else if (menuData.permission === "dashboard") {
        return true;
      } else {
        {
          return false;
        }
      }
    } else if (menuData && menuData.feature === "FORM_BUILDER") {
      // Check form builder permission permission
      return this.checkPermissionsCategory("formBuilder");
    } else if (menuData && menuData.feature === "ENTITIES") {
      // Check Entities permission
      return this.checkPermissionsCategory("entities");
    } else if (menuData && menuData.feature === "WORKFLOWS") {
      // Check workflow permission permission
      return this.checkPermissionsCategory("workflows");
      // return true;
    } else if (menuData && menuData.feature === "DOC_TEMPLATE") {
      // Check workflow permission permission
      // return this.checkPermissionsCategory("workflows");
      return true;
    } else if (menuData && menuData.feature === "FORM_TEMPLATE") {
      // Check workflow permission permission
      // return this.checkPermissionsCategory("workflows");
      return true;
    } else if (menuData && menuData.feature === "ENTITY_VIEWS") {
      // Entity view permissions need to be added
      return true;
    }
    return false;
  },
  onScreenResize() {
    window.addEventListener("resize", () => {
      this.updateScreenWidth();
    });
  },
  updateScreenWidth() {
    this.mql = window.screen.width;
    switch(true){
  // case  this.mql>1200:
  //   this.menuItemCount=8
  //   break;
  case  this.mql>1100:
    this.menuItemCount=7
    break;
  case this.mql>=1000:
    this.menuItemCount = 6
    break;
  case this.mql>900:
    this.menuItemCount = 5
    break;
  case this.mql>700:
    this.menuItemCount = 4
    break;
    case this.mql>500:
    this.menuItemCount = 2
    break;
    case this.mql>400:
    this.menuItemCount = 1
    break;
    case this.mql>80:
    this.menuItemCount = 0
    break;
}
    if(this.menu.length > this.menuItemCount){
      if(this.menuItemCount==1)
      {
        this.extraMenu = this.menu
      }
      else{
  this.mainMenu = this.menu.slice(0,this.menuItemCount)
  this.extraMenu = this.menu.slice(this.menuItemCount, this.menu.length)
      }
}
else{
  this.mainMenu = []
  this.mainMenu = [...this.mainMenu,...this.menu]
}

  },
}
};
</script>
<style>
body.modal-open {
height: 100vh;
background: #1b487e;
/* //overflow-y: hidden; */
}
.horizontal-navbar {
position: fixed;
height: 45px;
line-height: 45px;
top: 65px;
left: 0;
right: 0;
z-index: 999;
color: #ffffff;
box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05);
/* padding: 5px 10px; */
}
.horizontalMenu{
height:45px;
line-height:45px;
}
.el-menu--horizontal>.el-menu-item {
  float: left;
  height: 45px;
  line-height: 45px;
  margin: 0;
  border-bottom: 2px solid transparent;
  color: #909399;
}
.el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 45px;
  line-height: 45px;
  border-bottom: 2px solid transparent;
  color: #909399;
}
</style>

<style scoped>
.default-style{
.el-submenu__title {
  font-size: 14px;
  font-weight: 500 !important;
  padding: 0 20px;
}

.el-menu-item:focus, .el-menu-item:hover {
  outline: 0;
  background-color: var(--primary-color) !important;
  color: var(--primary-contrast-color) !important;
}

.el-submenu__title:hover {
  background-color: var(--primary-color) !important;
  color: var(--primary-contrast-color) !important;
}

.el-menu-item.is-active {
  background-color: var(--primary-color) !important;
  color: var(--primary-contrast-color) !important;
}
}

.inverted-color-style{
background-color: var(--sidemenu-background-color);

.el-menu-item {
  font-size: 13px;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--sidemenu-background-color) !important;
  color: var(--sidemenu-text-color) !important;
}

.el-submenu__title {
  font-size: 14px;
  font-weight: 500 !important;
  padding: 0 20px;
  cursor: pointer;
  -webkit-transition: border-color .3s, background-color .3s, color .3s;
  transition: border-color .3s, background-color .3s, color .3s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: var(--sidemenu-background-color) !important;
  color: var(--sidemenu-text-color) !important;
}

.el-menu-item:focus, .el-menu-item:hover {
  outline: 0;
  background-color: var(--sidemenu-text-color) !important;
  color: var(--sidemenu-background-color) !important;
}

.el-submenu__title:hover {
  background-color: var(--sidemenu-text-color) !important;
  color: var(--sidemenu-background-color) !important;
}

.el-menu-item.is-active {
  background-color: var(--sidemenu-text-color) !important;
  color: var(--sidemenu-background-color) !important;
}
}
</style>
